export function createEaseIn(power: number): (n: number) => number {
  return (n: number) => n ** power;
}

export function createEaseInOut(power: number): (n: number) => number {
  const r = 2 ** (power - 1);

  return (n: number) => {
    if (n < 0.5) {
      return r * n ** power;
    }
    n = 1 - n;

    return 1 - r * n ** power;
  };
}

export function createEaseOut(power: number): (n: number) => number {
  return (n: number) => {
    n = 1 - n;
    return 1 - n ** power;
  };
}

export function easeInExpo(n: number): number {
  return Math.pow(2, 10 * (n - 1)) - 0.001;
}

export function easeInOutExpo(n: number): number {
  return (n *= 2) < 1 ? 0.5 * Math.pow(2, 10 * (n - 1)) : 0.5 * (2 - Math.pow(2, -10 * (n - 1)));
}

export function easeOutExpo(n: number): number {
  return 1 - Math.pow(2, -10 * n);
}

import { styled } from '@/styles/stitches.config';
import type * as Stitches from '@stitches/react';
import { createContext, PropsWithChildren } from 'react';
import { BackButton } from './back-button';

export enum Themes {
  spinach = 'spinach',
  hutspot = 'hutspot',
  sunny = 'sunny',
  lime = 'lime',
  fire = 'fire',
  aubergenius = 'aubergenius',
  merlot = 'merlot',
  sky = 'sky',
}

type ThemeProps = PropsWithChildren<{ theme: Themes; hasBackButton?: boolean }>;

export const ThemeRoot = styled('div', {
  variants: {
    theme: {
      [Themes.spinach]: {
        '--color-background': '$colors$green0',
        '--color-primary': '$colors$yellow',
        '--color-secondary': '$colors$green1',
        '--color-tertiary': '$colors$purple0',
        '--color-text-light': '$colors$white',
        '--color-text-dark': '$colors$black',
      },
      [Themes.hutspot]: {
        '--color-background': '$colors$orange',
        '--color-primary': '$colors$yellow',
        '--color-secondary': '$colors$purple0',
        '--color-tertiary': '$colors$red',
        '--color-text-light': '$colors$white',
        '--color-text-dark': '$colors$black',
      },
      [Themes.sunny]: {
        '--color-background': '$colors$yellow',
        '--color-primary': '$colors$purple0',
        '--color-secondary': '$colors$red',
        '--color-tertiary': '$colors$orange',
        '--color-text-light': '$colors$black',
        '--color-text-dark': '$colors$black',
      },
      [Themes.lime]: {
        '--color-background': '$colors$green1',
        '--color-primary': '$colors$purple0',
        '--color-secondary': '$colors$green0',
        '--color-tertiary': '$colors$yellow',
        '--color-text-light': '$colors$black',
        '--color-text-dark': '$colors$black',
      },
      [Themes.fire]: {
        '--color-background': '$colors$red',
        '--color-primary': '$colors$yellow',
        '--color-secondary': '$colors$orange',
        '--color-tertiary': '$colors$purple1',
        '--color-text-light': '$colors$white',
        '--color-text-dark': '$colors$black',
      },
      [Themes.aubergenius]: {
        '--color-background': '$colors$purple0',
        '--color-primary': '$colors$yellow',
        '--color-secondary': '$colors$orange',
        '--color-tertiary': '$colors$red',
        '--color-text-light': '$colors$white',
        '--color-text-dark': '$colors$white',
      },
      [Themes.merlot]: {
        '--color-background': '$colors$purple1',
        '--color-primary': '$colors$yellow',
        '--color-secondary': '$colors$orange',
        '--color-tertiary': '$colors$red',
        '--color-text-light': '$colors$white',
        '--color-text-dark': '$colors$white',
      },
      [Themes.sky]: {
        '--color-background': '$colors$unicef',
        '--color-primary': '$colors$purple0',
        '--color-secondary': '$colors$yellow',
        '--color-tertiary': '$colors$orange',
        '--color-text-light': '$colors$white',
        '--color-text-dark': '$colors$black',
      },
    },
  },
});

export const ThemeStoryRoot = styled(ThemeRoot, {
  minHeight: '100vh',
  backgroundColor: 'var(--color-background)',
  borderRadius: '$space$3',
  boxShadow: '0 $space$2 $space$2 0 rgba(0, 0, 0, 0.3)',
});

export const ThemeContext = createContext<Themes>(Themes.sky);

export function getThemeVariants<T extends string>(styles: Partial<Record<T, Stitches.CSS>>) {
  return {
    ...Object.entries(Themes).reduce((acc, [key]) => ({ ...acc, [key]: {} }), {}),
    ...styles,
  } as unknown as Record<Themes, Stitches.CSS>;
}

export function Theme(props: ThemeProps) {
  const { hasBackButton, theme } = props;

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeStoryRoot theme={theme}>
        {hasBackButton && <BackButton />}
        {props.children}
      </ThemeStoryRoot>
    </ThemeContext.Provider>
  );
}

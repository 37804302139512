import { RefObject, useEffect, useRef, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement>(): [ref: RefObject<T>, size: Size] {
  const ref = useRef<T>(null);

  // we need to track mount state here to make sure we run the hook after mount
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  // Initial size on mount
  useEffect(() => {
    const callback = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    };

    const observer = new ResizeObserver(callback);

    if (mounted && ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [mounted]);

  return [ref, size];
}

/**
 * Gives the point at t for the given bezier curve
 * As per: https://en.wikipedia.org/wiki/B%C3%A9zier_curve#Cubic_B%C3%A9zier_curves
 */
export function cubicBezierPointAt(
  t: number,
  p1: Point2D,
  p2: Point2D,
  p3: Point2D,
  p4: Point2D
): Point2D {
  if (t < 0 || t > 1) {
    throw new Error('{t} is out of bounds');
  }

  const x =
    (1 - t) ** 3 * p1.x + 3 * (1 - t) ** 2 * t * p2.x + 3 * (1 - t) * t ** 2 * p3.x + t ** 3 * p4.x;

  const y =
    (1 - t) ** 3 * p1.y + 3 * (1 - t) ** 2 * t * p2.y + 3 * (1 - t) * t ** 2 * p3.y + t ** 3 * p4.y;

  return { x, y };
}

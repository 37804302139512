import type { GeoPermissibleObjects } from 'd3-geo';
import useSwrImmutable from 'swr/immutable';

export function useMapData(): {
  geometry?: GeoPermissibleObjects;
  countryCoordinates?: CountryCoordinate[];
} {
  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  const { data: geometry } = useSwrImmutable<GeoPermissibleObjects>('/api/topology', fetcher);

  const { data: countryCoordinates } = useSwrImmutable<CountryCoordinate[]>(
    '@/data/country-coordinates.json',
    async () => await import('@/data/country-coordinates.json').then((mod) => mod.default)
  );

  return { geometry, countryCoordinates };
}

import { styled } from '@/styles/stitches.config';

export const Label = styled('span', {
  display: 'inline-block',
  fontSize: '$2',
  fontWeight: '$medium',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  lineHeight: '$2',
  '[dir=rtl] &': {
    letterSpacing: 0,
  },
  '[lang=ta] &': {
    lineHeight: '$3',
  },
});

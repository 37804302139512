import { LinkTo } from './link-to';
import type { AnchorHTMLAttributes, MouseEvent, MouseEventHandler } from 'react';
import { ComposableStyledComponentProps } from '@/types/composable-styled-component';
import { styled } from '@/styles/stitches.config';

type LinkToButtonProps = ComposableStyledComponentProps<HTMLAnchorElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
    children: React.ReactNode;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
  };

const StyledLinkTo = styled(LinkTo, {
  display: 'grid',
  justifyItems: 'center',
  color: '$unicef',
  backgroundColor: '$white',
  paddingBlock: '$2',
  paddingInline: '$6',
  borderRadius: '$3',
  border: '1px solid $colors$unicef',

  '&:hover': {
    background: '$blue0',
    color: '$white',
    textDecoration: 'none',
    borderColor: '$blue0',
  },

  '&:focus-visible, &.focus-visible': {
    backgroundColor: '$blue0',
    color: '$white',
    textDecoration: 'none',
    borderColor: '$white',
    borderStyle: 'dotted',
    borderWidth: '3px',
    outline: 'none',
  },
});

export function LinkToButton(props: LinkToButtonProps) {
  const { as, children, href, onClick, ...rest } = props;

  return (
    <StyledLinkTo onClick={onClick} href={href} {...rest}>
      {children}
    </StyledLinkTo>
  );
}

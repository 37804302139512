import { t } from '@lingui/macro';
import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import { FC } from 'react';
import { isPresent } from 'ts-is-present';
import { AbsoluteUrl } from '../utils/get-absolute-url';

type SeoHeadProps = {
  baseUrl: AbsoluteUrl;
  pageTitle?: string;
  pageDescription?: string;
  sharingImage?: string;
};

export const SeoHead: FC<SeoHeadProps> = (props) => {
  const { baseUrl, pageTitle, pageDescription, sharingImage } = props;

  const router = useRouter();

  const title = pageTitle
    ? t({
        id: 'common.seo.title-with-page-title',
        message: '{pageTitle} | The Changing Childhood Project | UNICEF x Gallup',
        values: { pageTitle },
      })
    : t({
        id: 'common.seo.title-without-page-title',
        message: 'The Changing Childhood Project | UNICEF x Gallup',
      });

  const description = pageDescription
    ? pageDescription
    : t({
        id: 'common.seo.description',
        message:
          'Explore the interactive site to see how young people view our fast-changing world differently',
      });

  const sharingImageAvailableLocales = ['ar', 'es', 'en', 'fr'];

  const sharingImageLocale =
    isPresent(router.locale) && sharingImageAvailableLocales.includes(router.locale)
      ? router.locale
      : 'en';

  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="description" name="description" content={description} />

      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:type" property="og:type" content="website" />
      <meta
        key="og:url"
        property="og:url"
        content={`${baseUrl.origin}${
          router.defaultLocale === router.locale ? '' : `/${router.locale}`
        }${router.route}`}
      />
      <meta
        key="og:image"
        property="og:image"
        content={`${baseUrl.origin}${
          sharingImage ? sharingImage : `/sharing/${sharingImageLocale}/og-image.png`
        }?2`}
      />

      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta key="twitter:site" name="twitter:site" content="@unicef" />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:description" name="twitter:description" content={description} />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={`${baseUrl.origin}${
          sharingImage ? sharingImage : `/sharing/${sharingImageLocale}/og-image.png`
        }?2`}
      />

      {router.locales?.map((locale) => (
        <link
          key={`locale-${locale}`}
          rel="alternate"
          hrefLang={locale}
          href={`https://${baseUrl.host}/${locale}${router.route}`}
        />
      ))}
    </Head>
  );
};

import { GeoContext, GeoPath, geoPath, GeoPermissibleObjects, GeoProjection } from 'd3-geo';
import { RefObject, useMemo } from 'react';
import { useElementSize } from './use-element-size';

type MapProps<T extends HTMLElement> = {
  ref: RefObject<T>;
  projection?: GeoProjection;
  geoPath: (context?: GeoContext) => GeoPath<void, GeoPermissibleObjects>;
  width: number;
  height: number;
};

export function useMap<T extends HTMLElement>(
  baseProjection: () => GeoProjection,
  geometry?: GeoPermissibleObjects,
  fitToTile?: boolean,
  precision = 0.1
): MapProps<T> {
  const [ref, { width, height }] = useElementSize<T>();
  const [rw, rh] = [width, height].map(Math.round);
  const tileSize = rw > rh ? rh : rw;

  const projection = useMemo(() => {
    if (geometry && rw && rh) {
      return baseProjection()
        .precision(precision)
        .fitSize(fitToTile ? [tileSize, tileSize] : [rw, rh], geometry);
    }
  }, [baseProjection, fitToTile, tileSize, rw, rh, geometry, precision]);

  return useMemo(
    () => ({
      ref,
      projection,
      geoPath: (context?: GeoContext) => geoPath(projection, context),
      width: rw,
      height: rh,
    }),
    [projection, ref, rh, rw]
  );
}

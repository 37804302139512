import { styled } from '@/styles/stitches.config';
import { ComposableStyledComponentProps } from '@/types/composable-styled-component';
import { useContext } from 'react';
import { getThemeVariants, ThemeContext, Themes } from './microstory/theme';

const AnnotatedTypeRoot = styled('span', {
  fontWeight: '$semibold',
  borderRadius: '1em',
  paddingBlockStart: '0.125em',
  paddingBlockEnd: '0.2em',
  paddingInline: '0.5em',
  backgroundColor: '$unicef',
  color: '$white',
  boxDecorationBreak: 'clone',
  '-webkit-box-decoration-break': 'clone',
  '[lang=bn] &': {
    paddingBlockEnd: 0,
  },
  '[lang=hi] &, [lang=ta] &': {
    paddingBlockStart: '0.2em',
    paddingBlockEnd: '0.1em',
  },
  '[lang=ar] &': {
    display: 'inline-block',
    paddingBlock: '0.25em',
    lineHeight: '$1',
  },
  variants: {
    theme: getThemeVariants({
      [Themes.sky]: {
        backgroundColor: 'var(--color-tertiary)',
      },
    }),
  },
});

export const AnnotatedType = (props: ComposableStyledComponentProps) => {
  const theme = useContext(ThemeContext);
  return <AnnotatedTypeRoot theme={theme} {...props} />;
};

const PrimaryAnnotatedTypeRoot = styled(AnnotatedTypeRoot, {
  backgroundColor: 'var(--color-primary)',
  variants: {
    theme: getThemeVariants({
      [Themes.spinach]: {
        color: '$black',
      },
      [Themes.hutspot]: {
        color: '$black',
      },
      [Themes.fire]: {
        color: '$black',
      },
      [Themes.aubergenius]: {
        color: '$black',
      },
      [Themes.merlot]: {
        color: '$black',
      },
      [Themes.sky]: {
        backgroundColor: 'var(--color-primary)',
      },
    }),
  },
});

export const PrimaryAnnotatedType = (props: ComposableStyledComponentProps) => {
  const theme = useContext(ThemeContext);
  return <PrimaryAnnotatedTypeRoot theme={theme} {...props} />;
};

const SecondaryAnnotatedTypeRoot = styled(AnnotatedTypeRoot, {
  backgroundColor: 'var(--color-secondary)',
  variants: {
    /* Set color to black for themes that have --color-yellow as secondary color */
    theme: getThemeVariants({
      [Themes.lime]: {
        color: '$black',
      },
      [Themes.sky]: {
        backgroundColor: 'var(--color-secondary)',
        color: '$black',
      },
    }),
  },
});

export const SecondaryAnnotatedType = (props: ComposableStyledComponentProps) => {
  const theme = useContext(ThemeContext);
  return <SecondaryAnnotatedTypeRoot theme={theme} {...props} />;
};

const TertiaryAnnotatedTypeRoot = styled(AnnotatedTypeRoot, {
  backgroundColor: 'var(--color-tertiary)',
  variants: {
    theme: getThemeVariants({
      [Themes.lime]: {
        /* Set color to black for themes that have --color-yellow as tertiary color */
        color: '$black',
      },
      [Themes.sky]: {
        backgroundColor: 'var(--color-tertiary)',
      },
    }),
  },
});

export const TertiaryAnnotatedType = (props: ComposableStyledComponentProps) => {
  const theme = useContext(ThemeContext);
  return <TertiaryAnnotatedTypeRoot theme={theme} {...props} />;
};

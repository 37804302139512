import { visuallyHidden } from '../../styles/predefined';

import Link from 'next/link';
import { Trans } from '@lingui/macro';
import IconCaretLeft from '@/assets/svg/icon-caret-left';
import { styled } from '@/styles/stitches.config';

const BackButtonRoot = styled('div', {
  position: 'sticky',
  insetBlockStart: 0,
  insetInlineStart: 0,
  height: 0,
  width: 0,
  pointerEvents: 'none',
  zIndex: 2,
});

const BackButtonAnchor = styled('span', {
  $$size: '$space$7',

  position: 'absolute',
  insetBlockStart: '$3',
  insetInlineStart: '$3',

  pointerEvents: 'auto',

  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  width: '$$size',
  height: '$$size',

  backgroundColor: 'var(--color-background)',
  borderRadius: '1.5em',
  border: '2px solid $colors$white',
  color: '$white',

  '&:hover': {
    backgroundColor: '$white',
    color: '$blue0',
  },

  '&:hover, &:active': {
    backgroundColor: '$white',
    color: '$blue0',
  },

  '&:focus-visible, &.focus-visible': {
    backgroundColor: '$white',
    color: '$blue0',
    outlineColor: '$white',
    outlineStyle: 'dotted',
    outlineWidth: 3,
  },
});

const HiddenSpan = styled('span', visuallyHidden);

export const BackButton = () => {
  return (
    <BackButtonRoot>
      <Link href="/stories" passHref>
        <BackButtonAnchor>
          <IconCaretLeft />
          <HiddenSpan>
            <Trans id="back-button.text">Back to Stories Overview</Trans>
          </HiddenSpan>
        </BackButtonAnchor>
      </Link>
    </BackButtonRoot>
  );
};

import { AnnotatedType } from '@/components/annotated-type';
import { Label } from '@/components/label';
import { LinkToButton } from '@/components/link-to-button';
import { SeoHead } from '@/components/seo-head';
import { WorldMap } from '@/components/world-map/canvas';
import { useStore } from '@/store';
import { keyframes, styled } from '@/styles/stitches.config';
import { getAbsoluteUrl } from '@/utils/get-absolute-url';
import { loadMessages } from '@/utils/i18n';
import { useLayout } from '@/utils/use-layout';
import { useSessionStorage } from '@/utils/use-session-storage';
import { Trans } from '@lingui/macro';
import { motion, useScroll } from 'framer-motion';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';

const Root = styled('section', {
  position: 'relative',
  minHeight: 'calc(100vh - var(--header-height))',
});

const StepContainer = styled('div', {
  $$height: 'calc(75vh - var(--header-height))',
  position: 'relative',
  minHeight: '$$height',
  paddingBlock: '$6',
  paddingInline: '$4',
  display: 'grid',
  zIndex: 1,
  /* 
     This hack makes sure the texts are on top of the map in Safari, 
     which has a bug related to position: sticky; and z-indexes.
     See: https://stackoverflow.com/questions/50224855/not-respecting-z-index-on-safari-with-position-sticky
   */
  transform: 'translate3d(0, 0, 0)',

  '@bp4': {
    $$height: 'calc(80vh - var(--header-height))',
  },
});

const FirstStepContainer = styled(StepContainer, {
  $$height: 'calc(100vh - var(--header-height))',
  /* warning: dirty hacks be here */
  /* overlays the first step on top of the sticky map container */
  marginBlockStart: 'calc(-1 * (100vh - var(--header-height)))',
});

const P = styled('p', {
  lineHeight: '$3',

  maxWidth: '25ch',
  alignSelf: 'start',
  marginBlock: '7vmax',

  fontSize: '$4',
  fontWeight: '$bold',
  color: '$white',

  '@bp2': {
    marginInline: 'auto',
    fontSize: '$5',
  },

  '[lang=ta] &': {
    fontSize: '$3',
  },

  variants: {
    hidden: {
      true: {
        opacity: 0,
        transition: 'opacity 500ms ease-in',
      },
      false: {
        opacity: 1,
        transition: 'none',
      },
    },
  },
});

const StepContent = styled('div', {
  display: 'grid',
  minHeight: '100%',
});

const Text = styled('p', {});

const ScrollDownRoot = styled('button', {
  all: 'unset',
  cursor: 'pointer',
  display: 'grid',
  justifyContent: 'center',
  justifyItems: 'center',
  width: '100%',
  gridTemplateRows: 'repeat(2, min-content)',
});

const down = keyframes({
  '0%': {
    transform: 'translate(0)',
  },
  '20%': {
    transform: 'translateY($space$4)',
  },
  '40%': {
    transform: 'translate(0)',
  },
});

const ScrollArrow = styled(motion.div, {
  width: '$space$7',
  height: '$space$7',
  border: '2px solid $colors$white',
  borderRadius: '50%',
  position: 'relative',
  animation: `${down} 1.5s infinite`,
  animationDirection: 'alternate',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '$space$4',
    height: '$space$4',
    borderLeft: '2px solid $colors$white',
    borderBottom: '2px solid $colors$white',
    transform: 'translate(-50%, -70%) rotate(-45deg)',
  },
});

function ScrollDown(props: { scrollId?: string }) {
  const { scrollId } = props;

  const handleClick = () => {
    if (scrollId) {
      const el = document.querySelector(`#${scrollId}`);
      el?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ScrollDownRoot onClick={handleClick}>
      <Label
        as={'p'}
        css={{
          color: '$white',
          marginBlockEnd: '$4',
        }}
      >
        <Trans id="introduction.scroll-down">Scroll down</Trans>
      </Label>
      <ScrollArrow />
    </ScrollDownRoot>
  );
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const baseUrl = getAbsoluteUrl(ctx.req);
  const messages = await loadMessages(ctx.locale);

  return {
    props: {
      messages,
      baseUrl,
    },
  };
}

export default function Home(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const router = useRouter();

  useLayout('blue');

  // if folks land on the homepage, don't show them the SurveyBackground component on story pages
  const [_, setShowSurveyBackground] = useSessionStorage('showSurveyBackground', false);
  useEffect((): void => setShowSurveyBackground(false), [setShowSurveyBackground]);

  const [isCurtainUp, setCurtainUp] = useState(false);
  const { scrollYProgress } = useScroll();
  const [isOutroAnimation, setOutroAnimation] = useState(false);

  const prefersReducedMotion = useStore((state) => state.prefersReducedMotion);

  useEffect(() => {
    const unsubscribe = scrollYProgress.on('change', (p) => {
      const scrollThreshold = 0.67;
      // we want the curtain to stick once we're doing the outro animation
      setCurtainUp(isOutroAnimation ? true : p > scrollThreshold);
    });

    return () => unsubscribe();
  }, [scrollYProgress, isOutroAnimation]);

  useEffect(() => {
    if (isOutroAnimation) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isOutroAnimation]);

  const onDiveIn = (e: MouseEvent) => {
    if (!prefersReducedMotion) {
      e.preventDefault();
      router.prefetch('/stories');
      setOutroAnimation(true);
    }
  };

  const handleOutroAnimationComplete = () => {
    router.push('/stories');
  };

  return (
    <>
      <SeoHead baseUrl={props.baseUrl} />

      <Root>
        <WorldMap
          css={{
            position: 'sticky',
            zIndex: 0,
            top: 0,
            left: 0,
            height: '100vh',
          }}
          isCurtainUp={isCurtainUp}
          isOutroAnimation={isOutroAnimation}
          hasOpaqueTiles={isCurtainUp}
          handleOutroAnimationComplete={handleOutroAnimationComplete}
        />

        <FirstStepContainer>
          <StepContent css={{ gridTemplateRows: 'repeat(2, 1fr)', alignItems: 'center' }}>
            <P as="h1" hidden={isOutroAnimation}>
              <Trans id="home.title">
                The{' '}
                <AnnotatedType
                  css={{
                    backgroundColor: '$white',
                    color: '$unicef',
                  }}
                >
                  Changing Childhood
                </AnnotatedType>{' '}
                Project
              </Trans>
            </P>
            {!isOutroAnimation && <ScrollDown scrollId="step2" />}
          </StepContent>
        </FirstStepContainer>

        <StepContainer id={'step2'}>
          <StepContent>
            <P hidden={isOutroAnimation}>
              <Trans id="intro.text-1">
                We asked young and older people in{' '}
                <AnnotatedType css={{ backgroundColor: '$white', color: '$unicef' }}>
                  55 countries
                </AnnotatedType>
              </Trans>
            </P>
          </StepContent>
        </StepContainer>

        <StepContainer>
          <StepContent>
            <P hidden={isOutroAnimation}>
              <Trans id="intro.text-2">
                What is it like to{' '}
                <AnnotatedType css={{ backgroundColor: '$white', color: '$unicef' }}>
                  grow up in today’s world?
                </AnnotatedType>
              </Trans>
            </P>
          </StepContent>
        </StepContainer>

        <StepContainer>
          <StepContent>
            <P hidden={isOutroAnimation}>
              <Trans id="intro.text-3">
                And how do generations{' '}
                <AnnotatedType css={{ backgroundColor: '$white', color: '$unicef' }}>
                  view the world differently?
                </AnnotatedType>
              </Trans>
            </P>
          </StepContent>
        </StepContainer>

        <StepContainer>
          <StepContent
            css={{
              gridTemplateRows: 'repeat(2, 1fr)',
              alignItems: 'center',
            }}
          >
            <P
              hidden={isOutroAnimation}
              css={{
                color: '$unicef',
              }}
            >
              <Trans id="intro.text-4">
                Dive in and discover the{' '}
                <AnnotatedType css={{ backgroundColor: '$unicef', color: '$white' }}>
                  changing nature of childhood
                </AnnotatedType>
              </Trans>
            </P>
            <Text css={{ alignSelf: 'start', justifySelf: 'center' }}>
              <LinkToButton
                css={{
                  backgroundColor: '$blue0',
                  color: '$white',
                  border: '2px solid $colors$blue0',
                  opacity: isOutroAnimation ? 0 : 1,
                  pointerEvents: isOutroAnimation ? 'none' : 'auto',
                  transition: isOutroAnimation ? 'opacity 500ms ease-in' : 'none',

                  '&:hover': {
                    borderWidth: 2,
                    borderColor: '$white',
                  },

                  '&:focus-visible, &.focus-visible': {
                    borderWidth: 2,
                    borderStyle: 'dotted',
                    borderColor: '$white',
                  },
                }}
                hidden={isOutroAnimation}
                onClick={onDiveIn}
                href="/stories"
              >
                <Trans id="intro.dive-in-button">Dive in!</Trans>
              </LinkToButton>
            </Text>
          </StepContent>
        </StepContainer>
      </Root>
    </>
  );
}

import { styled } from '@/styles/stitches.config';
import { ComposableStyledComponentProps } from '@/types/composable-styled-component';
import { cubicBezierPointAt } from '@/utils/cubic-bezier-point-at';
import { distance } from '@/utils/distance';
import { createEaseIn, easeInExpo } from '@/utils/easing-functions';
import { hexToRgb } from '@/utils/hex-to-rgb';
import { lerp } from '@/utils/lerp';
import { modulo } from '@/utils/modulo';
import { norm } from '@/utils/norm';
import { rotatePoint2D } from '@/utils/rotate-point-2d';
import { useMap } from '@/utils/use-map';
import { useMapData } from '@/utils/use-map-data';
import usePrevious from '@/utils/use-previous';
import { geoGraticule10 } from 'd3-geo';
import { geoPeirceQuincuncial } from 'd3-geo-projection';
import { animate, useMotionValue } from 'framer-motion';
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { isPresent } from 'ts-is-present';

type WorldMapProps = ComposableStyledComponentProps<HTMLDivElement> &
  PropsWithChildren<{
    // offset from the center (-1 to 1)
    offset?: number;
    isCurtainUp?: boolean;
    isOutroAnimation?: boolean;
    hasOpaqueTiles?: boolean;
    handleOutroAnimationComplete?: () => void;
  }>;

type Tile = {
  tileX: number;
  tileY: number;
  tileWidth: number;
  tileHeight: number;
  tileMapX: number;
  tileMapY: number;
  column: number;
  row: number;
};

type RenderedPoint = {
  x: number;
  y: number;
  radius: number;
  color: (typeof colors)[number];
};

type AnimatingPoint = {
  progress: number;
  startRadius: number;
  endRadius: number;
  size: number;
  color: string;
  p1: Point2D;
  p2: Point2D;
  p3: Point2D;
  p4: Point2D;
  positionEase: (n: number) => number;
  radiusEase: (n: number) => number;
  stop: () => void;
};

const easeInCubic = createEaseIn(3);

const MapRoot = styled('div', {
  maxWidth: '100%',
});

const Canvas = styled('canvas', {
  display: 'block',
});

const Tiles = styled('canvas', {
  display: 'none',
});

const unicefBlue = '#00aeef';
const yellow = '#ffc20e';

const colors = [
  unicefBlue,
  '#00833d',
  '#80bd41',
  yellow,
  '#f26a21',
  '#e2231a',
  '#6a1e74',
  '#961a49',
] as const;

const unicefBlueRgb = hexToRgb(unicefBlue);

function roundedRectangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

/**
 * Returns crop and draw information for each tile based on given properties for tiled map
 */
const getTileLayout = (
  x: number,
  y: number,
  mapWidth: number,
  mapHeight: number,
  tileSize: number
): Tile[] => {
  const tiles: Tile[] = [];
  const startTileX = modulo(x, tileSize);
  let tileX = startTileX;
  let tileY = modulo(y, tileSize);
  let tileMapX = 0;
  let tileMapY = 0;

  const startColumn = Math.floor(x / tileSize);
  let column = startColumn;
  let row = Math.floor(y / tileSize);

  let busy = true;
  while (busy) {
    if (tileMapY >= mapHeight) {
      busy = false;
      break;
    }

    const tileWidth = Math.min(tileSize - tileX, mapWidth - tileMapX);
    const tileHeight = Math.min(tileSize - tileY, mapHeight - tileMapY);

    tiles.push({ tileX, tileY, tileWidth, tileHeight, tileMapX, tileMapY, column, row });

    if (tileMapX + tileWidth >= mapWidth) {
      tileMapX = 0;
      tileMapY += tileHeight;
      tileX = startTileX;
      tileY = 0;
      column = startColumn;
      row += 1;
    } else {
      tileX = 0;
      tileMapX += tileWidth;
      column += 1;
    }
  }

  return tiles;
};

const getRenderedPoints = (
  points: [x: number, y: number][],
  tileLayout: Tile[],
  tileSize: number
) => {
  const renderedPoints: RenderedPoint[] = [];

  tileLayout.forEach((tile) => {
    const { tileX, tileY, tileMapX, tileMapY, tileWidth, tileHeight, row, column } = tile;
    const shouldRotate = (row + column) % 2 !== 0;

    points.forEach(([x, y], index) => {
      const radius = 5;

      if (shouldRotate) {
        x = tileSize - x;
        y = tileSize - y;
      }

      x = tileMapX + x - tileX;
      y = tileMapY + y - tileY;

      // prevent drawing out of bounds of tile
      if (
        x < tileMapX - radius * 0.5 ||
        x > tileMapX + tileWidth + radius * 0.5 ||
        y < tileMapY - radius * 0.5 ||
        y > tileMapY + tileHeight + radius * 0.5
      ) {
        return;
      }

      renderedPoints.push({ x, y, radius, color: colors[index % colors.length] });
    });
  });

  return renderedPoints;
};

export function WorldMap(props: WorldMapProps) {
  const { css, handleOutroAnimationComplete, hasOpaqueTiles, isCurtainUp, isOutroAnimation } =
    props;

  const { geometry, countryCoordinates } = useMapData();

  const [readyToRender, setReadyToRender] = useState(false);

  const canvas = useRef<HTMLCanvasElement>(null);
  const tile = useRef<HTMLCanvasElement>(null);
  const panOffset = useRef({ x: 0, y: 0 });
  const tileLayout = useRef<Tile[]>();
  const renderedPoints = useRef<RenderedPoint[]>();
  const animatingPoints = useRef<AnimatingPoint[]>([]);

  const {
    width,
    height,
    projection,
    geoPath,
    ref: root,
  } = useMap<HTMLDivElement>(geoPeirceQuincuncial, geometry, true);

  const tileSize = width > height ? height : width;

  const outroAnimationOverlay = useMotionValue(0);

  // setup transition animation once outro animation starts
  useEffect(() => {
    if (isOutroAnimation && renderedPoints.current) {
      const maxRadius = Math.sqrt(width * width + height * height) * 0.5;
      let animationDuration = 0;

      // we're taking the points that are currently on the screen to do our animation with
      animatingPoints.current = renderedPoints.current.map((p) => {
        const delay = Math.random() * 0.2;
        const duration = 3 + Math.random() * 0.5;

        animationDuration = Math.max(delay + duration, animationDuration);

        // the animation of the point is dictated by this linear tween that goes from 0 to 1
        const progress = animate(0, 1, {
          type: 'tween',
          ease: 'linear',
          delay,
          duration,
          onUpdate(value) {
            point.progress = value;
          },
        });

        // constructing a cubic bezier curve (p1, p2, p3, p4) for the outro transition
        // the point will follow it's trajectory and scale accordingly
        let p1 = { x: p.x, y: p.y };
        let p4 = {
          x: width * 0.5,
          y: height * 0.5,
        };
        const cp = { x: lerp(0.5, p1.x, p4.x), y: lerp(0.5, p1.y, p4.y) };
        const dx = p4.x - p1.x;
        const dy = p4.y - p1.y;
        const a = Math.atan2(dy, dx);
        const d = distance(p1, p4);
        p1 = { x: cp.x - d * 0.5, y: cp.y };
        p4 = { x: cp.x + d * 0.5, y: cp.y };
        const signX = Math.random() > 0.5 ? 1 : -1;
        const signY = 1;
        const xOffset = Math.random() * maxRadius * 0.1 + maxRadius * 0.2;
        const yOffset = Math.random() * maxRadius * 0.1 + maxRadius * 0.2;
        let p2 = {
          x: p1.x + xOffset * signX,
          y: p1.y + yOffset * signY,
        };
        let p3 = {
          x: p4.x + xOffset * -signX,
          y: p4.y + yOffset * signY,
        };
        p1 = rotatePoint2D(p1, a, cp);
        p2 = rotatePoint2D(p2, a, cp);
        p3 = rotatePoint2D(p3, a, cp);
        p4 = rotatePoint2D(p4, a, cp);

        const point = {
          progress: 0,
          size: 0,
          color: p.color,
          p1,
          p2,
          p3,
          p4,
          startRadius: p.radius,
          endRadius: maxRadius,
          positionEase: easeInCubic,
          radiusEase: easeInExpo,
          stop() {
            progress.stop();
          },
        };

        return point;
      });

      // near the end of the outro animation we trigger an animation that fills the screen with one solid color
      animate(outroAnimationOverlay, 1, {
        type: 'tween',
        delay: animationDuration - 0.25,
        duration: 0.25,
        ease: 'easeIn',
      });

      // trigger callback once all points are finished animating
      const timeoutId = setTimeout(() => {
        if (handleOutroAnimationComplete) {
          handleOutroAnimationComplete();
        }
      }, animationDuration * 1000);

      return () => {
        if (animatingPoints.current) {
          animatingPoints.current.forEach((point) => point.stop());
        }
        clearTimeout(timeoutId);
      };
    }
  }, [isOutroAnimation, width, height, handleOutroAnimationComplete, outroAnimationOverlay]);

  // calculate point positions
  const points: [x: number, y: number][] | undefined = useMemo(() => {
    if (isPresent(projection) && isPresent(countryCoordinates)) {
      return countryCoordinates.map((coordinate) => {
        const [lat, lng] = coordinate.latlng;
        if (projection) {
          return projection([lng, lat]) as [x: number, y: number];
        }
        return [0, 0];
      });
    }
  }, [projection, countryCoordinates]);

  // handle curtain animation
  const isCurtainUpPrevious = usePrevious(isCurtainUp);
  const curtain = useMotionValue(isCurtainUp ? 0 : height);
  useEffect(() => {
    const controls = animate(curtain, isCurtainUp ? 0 : height, {
      duration: isCurtainUpPrevious === isCurtainUp ? 0 : 0.75,
    });
    return controls.stop;
  }, [isCurtainUp, isCurtainUpPrevious, curtain, height]);

  // handle globalAlpha
  const globalAlpha = useMotionValue(hasOpaqueTiles ? 0.6 : 1);
  useEffect(() => {
    const controls = animate(globalAlpha, hasOpaqueTiles ? 0.6 : 1, { duration: 0.75 });
    return controls.stop;
  }, [hasOpaqueTiles, globalAlpha]);

  // drawing the world map
  useEffect(() => {
    if (isPresent(geometry) && tile.current) {
      const scale = window.devicePixelRatio;
      tile.current.width = Math.floor(tileSize * scale);
      tile.current.height = Math.floor(tileSize * scale);

      const ctx = tile.current.getContext('2d');

      if (ctx && width && height) {
        ctx.scale(scale, scale);

        const path = geoPath(ctx);
        const graticule = geoGraticule10();

        const graticuleGradient = ctx.createRadialGradient(
          tileSize / 2,
          tileSize / 2,
          0,
          tileSize / 2,
          tileSize / 2,
          tileSize / 2
        );

        graticuleGradient.addColorStop(0, 'rgba(149, 222, 249, 1)');
        graticuleGradient.addColorStop(0.8, 'rgba(149, 222, 249, 0.6)');
        graticuleGradient.addColorStop(1, 'rgba(149, 222, 249, 0)');

        const drawGraticule = () => {
          ctx.beginPath();
          path(graticule);
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = graticuleGradient;
          ctx.stroke();
        };

        const drawGeometry = () => {
          ctx.beginPath();
          path(geometry);
          ctx.fillStyle = unicefBlue;
          ctx.fill();
          ctx.closePath();
        };

        const render = () => {
          if (tile.current) {
            ctx.clearRect(0, 0, tile.current.width, tile.current.height);
            drawGeometry();
            drawGraticule();
            setReadyToRender(true);
          }
        };

        render();
      }
    }
  }, [geoPath, geometry, height, projection, tileSize, width]);

  // main draw loop
  useEffect(() => {
    if (canvas.current && tile.current && readyToRender) {
      const ctx = canvas.current.getContext('2d', { alpha: false });
      if (ctx) {
        // scale for retina
        const scale = window.devicePixelRatio;
        canvas.current.width = Math.floor(width * scale);
        canvas.current.height = Math.floor(height * scale);
        ctx.scale(scale, scale);

        const drawTiles = (tileLayout: Tile[]) => {
          ctx.globalAlpha = globalAlpha.get();
          tileLayout.forEach((d) => {
            let { tileX, tileY } = d;
            const { tileWidth, tileHeight, tileMapX, tileMapY, column, row } = d;

            ctx.save();

            const shouldRotate = (row + column) % 2 !== 0;
            if (shouldRotate) {
              // for the tiles we want to rotate we need to adjust the crop
              tileX = tileSize - tileWidth - tileX;
              tileY = tileSize - tileHeight - tileY;

              const cx = tileMapX + tileWidth * 0.5;
              const cy = tileMapY + tileHeight * 0.5;
              ctx.translate(cx, cy);
              ctx.rotate(Math.PI);
              ctx.translate(-cx, -cy);
            }

            ctx.drawImage(
              tile.current as HTMLCanvasElement,
              tileX * scale,
              tileY * scale,
              tileWidth * scale,
              tileHeight * scale,
              tileMapX,
              tileMapY,
              tileWidth,
              tileHeight
            );

            ctx.restore();
          });
          ctx.globalAlpha = 1;
        };

        const drawOverlay = () => {
          ctx.globalCompositeOperation = 'multiply';
          ctx.beginPath();
          ctx.rect(0, 0, width, curtain.get());
          ctx.fillStyle = unicefBlue;
          ctx.fill();
          ctx.globalCompositeOperation = 'source-over';
        };

        const drawPoints = (points: RenderedPoint[]) => {
          points.forEach(({ x, y, radius, color }) => {
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, Math.PI * 2);
            ctx.fillStyle = y < curtain.get() ? 'white' : color;
            ctx.fill();
          });
        };

        const drawAnimatingPoints = () => {
          // make sure to sort before we draw (smaller ones on top)
          animatingPoints.current.sort(function (a, b) {
            return b.size - a.size;
          });

          animatingPoints.current.forEach((item) => {
            const {
              p1,
              p2,
              p3,
              p4,
              startRadius,
              endRadius,
              progress,
              positionEase,
              radiusEase,
              color,
            } = item;

            // get point on trajectory
            const p = cubicBezierPointAt(positionEase(progress), p1, p2, p3, p4);

            const maxDistance = distance(p1, p4);
            const d = distance(p, p4);

            // basing point position + size based on point on trajectory and distance from end point
            const n = norm(d, maxDistance, 0);
            const r = lerp(radiusEase(n), startRadius, endRadius);
            const w = r * 2;
            const h = r * 2 * (1 + Math.max(n, 0) * 0.35); // taking aspect ratio of card into account but not when it's too small
            const x = p.x - w * 0.5;
            const y = p.y - h * 0.5;

            item.size = r;

            roundedRectangle(ctx, x, y, w, h, Math.min(r, 100));
            ctx.fillStyle = color;
            ctx.fill();
          });
        };

        const panSpeedX = isOutroAnimation ? 0 : 1;
        const panSpeedY = isOutroAnimation ? 0 : 1;

        const frame = 1000 / 60;

        const render: FrameRequestCallback = (d) => {
          const fpsCorrection = !prevTime ? 1 : (d - prevTime) / frame;
          prevTime = d;
          panOffset.current.x += panSpeedX * fpsCorrection;
          panOffset.current.y += panSpeedY * fpsCorrection;

          ctx.clearRect(0, 0, width, height);
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, width, height);

          tileLayout.current = getTileLayout(
            panOffset.current.x,
            panOffset.current.y,
            width,
            height,
            tileSize
          );

          if (points) {
            renderedPoints.current = getRenderedPoints(points, tileLayout.current, tileSize);
          }

          drawTiles(tileLayout.current);
          drawOverlay();

          if (!isOutroAnimation && renderedPoints.current) {
            drawPoints(renderedPoints.current);
          } else {
            drawAnimatingPoints();
          }

          // drawing the outro overlay once that animation started
          if (outroAnimationOverlay.get() && unicefBlueRgb) {
            ctx.fillStyle = `rgba(${unicefBlueRgb.r}, ${unicefBlueRgb.g}, ${
              unicefBlueRgb.b
            }, ${outroAnimationOverlay.get()})`;
            ctx.fillRect(0, 0, width, height);
          }

          id = requestAnimationFrame(render);
        };

        let prevTime: number;
        let id = requestAnimationFrame(render);
        return () => {
          cancelAnimationFrame(id);
        };
      }
    }
  }, [
    height,
    curtain,
    readyToRender,
    tile,
    tileSize,
    width,
    points,
    isOutroAnimation,
    animatingPoints,
    outroAnimationOverlay,
    globalAlpha,
  ]);

  return (
    <MapRoot ref={root} css={css}>
      <Canvas ref={canvas} css={{ width, height }} />
      <Tiles ref={tile} css={{ width: tileSize, height: tileSize }} />
    </MapRoot>
  );
}

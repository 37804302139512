/**
 * Rotates a 2d point for the given angle (in radians) around an optional pivot point
 */
export function rotatePoint2D(
  point: Point2D,
  angle: number,
  pivot: Point2D = { x: 0, y: 0 }
): Point2D {
  const dx = point.x - pivot.x;
  const dy = point.y - pivot.y;

  return {
    x: dx * Math.cos(angle) - dy * Math.sin(angle) + pivot.x,
    y: dy * Math.cos(angle) + dx * Math.sin(angle) + pivot.y,
  };
}

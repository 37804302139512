function IconCaretLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={11} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.503 20.992l-10-9.822 10-9.985"
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconCaretLeft;
